// =============================================================================
// Root
// =============================================================================
.c-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-foreground;

	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	min-height: 100vh;
	overflow: auto;

	background-color: set-color("background" "dark");

	pointer-events: none;
}

// =============================================================================
// Elements
// =============================================================================
.c-overlay__container {
	height: 100%;
	// necessary in IE11 to keep the container in the middle of the screen
	margin: 0;
}

.c-overlay__button {
	position: absolute;
	top: 2rem;
	right: 2rem;

	padding: 0;

	border: none;
	background-color: transparent;

	cursor: pointer;
}

.c-overlay__icon {
	width: 2.8rem;
	height: 2.8rem;

	color: set-color("text" "inverse");
}

.c-overlay__content {
	display: flex;
	align-items: center;
	height: 100%;
}

.c-overlay__arrow {
	padding: 0;

	border: none;
	background-color: transparent;
}

.c-overlay__image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	overflow: hidden;
}

.c-overlay__image {
	max-height: 100%;
}

.c-overlay__arrow-icon {
	width: 4rem;
	height: 4rem;

	color: set-color("text" "inverse");

	@include respond-at("viewport-4") {
		width: 5rem;
		height: 5rem;
	}
}
