// =============================================================================
// Variables
// =============================================================================

/**
 * The main definition of each color, named after it hue and lightness value. DO
 * NOT use these colors outside this file. These are used to define the
 * $lmr-sass-colors.
 */

$black-0: hsl(0, 0%, 0%); // #000000

$white-100: hsl(0, 0%, 100%); // #FFFFFF

$grey-10: hsl(0, 0%, 10%);
$grey-12: hsl(0, 0%, 12%);
$grey-20: hsl(0, 0%, 20%);

$fjdsf: hsl(0, 0%, 12%);

$green-94: hsl(163, 94%, 40%);
$green-145: hsl(175, 43%, 42%);

// =============================================================================
// Colors
// =============================================================================

/**
 * Main color map used for the distribution of colors throughout the styles.
 *
 * Use numbers (1, 2, 3) to create importance or shorthand for primary,
 * secondary, tertiary, ... .
 * Use "default" key as the main color within a category.
 * Use "inverse" to indicated an inverted color within that category.
 * Use "on" keyword to indicate which color is used on a certain color.
 * Use "alt" keyword to indicate an alternative color
 *
 * https://www.npmjs.com/package/@littlemissrobot/sass-colors
 */

$lmr-sass-colors: (
	source: (
		brand: (
			primary: $white-100
		),
		background: (
			default: $white-100,
			dark: $black-0
		),
		text: (
			default: rgba($grey-10, 0.7),
			dark: rgba($grey-10, 0.9),
			inverse: $white-100
		),
		button: (
			default: $green-94,
			inverse: $white-100
		),
		link: (
			default: $green-145
		),
		hamburger: (
			default: $white-100
		),
		footer: (
			default: $grey-12,
			light: $grey-20
		),
	)
);
