/* stylelint-disable selector-no-qualifying-type */
// =============================================================================
// Root
// =============================================================================
html {

	&.has-scroll-disabled {
		overflow: hidden;
	}
}
