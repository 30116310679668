// =============================================================================
// Root
// =============================================================================
.c-divider {
	width: 100%;
	height: 3rem;
	margin-top: 3rem;

	@include respond-at("viewport-7") {
		margin-top: 10rem;
	}

	background-size: 45rem;
	background-position: center;
	background-repeat: no-repeat;
}

// =============================================================================
// Elements
// =============================================================================
.c-divider__visual {
	@include visually-hidden();
}
