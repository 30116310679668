// =============================================================================
// Elements
// =============================================================================
.c-logo__link {
	@include set-typo("logo");

	width: 18rem;

	color: set-color("text" "inverse");

	@include respond-at("viewport-9") {
		width: 22rem;
	}
}
