// =============================================================================
// Border width
// =============================================================================
$border-width-default: 0.1rem;

// =============================================================================
// Border radius
// =============================================================================
$border-radius-small: 0.5rem;
$border-radius-default: 4rem;
$border-radius-round: 50%;

// =============================================================================
// Border
// =============================================================================
$border-default: 0.2rem solid set-color("button" "default");
$border-light: 0.2rem solid set-color("button" "inverse");
