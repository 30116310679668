// =============================================================================
// :: Section
// =============================================================================
.l-section {
	padding: 4rem 0;

	@include respond-at("viewport-7") {
		padding: 8rem 0;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.l-section__title {
	margin-bottom: 2rem;

	@include respond-at("viewport-7") {
		margin-bottom: 3rem;
	}
}
