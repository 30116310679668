// =============================================================================
// Root
// =============================================================================
.c-link {
	@include set-typo("link");

	padding-bottom: 0.2rem;

	text-decoration: none;

	color: set-color("link" "default");

	&:hover {

		.c-link__text {
			background-size: 100% $border-width-default;
		}
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-link__text {
	background-image:
		linear-gradient(
			set-color("link" "default"),
			set-color("link" "default")
		);
	background-position: 0% 100%;
	background-repeat: no-repeat;
	background-size:
		0% $border-width-default,
		100% $border-width-default;

	transition: background-size $animation-duration ease-in-out;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-button--inverted {
	background-color: transparent;
	border: $border-light;
	color: set-color("text" "inverse");

	transition:
		background-color $animation-duration ease-in-out,
		color $animation-duration ease-in-out;

	&:hover {
		background-color: set-color("button" "inverse");
		color: set-color("text" "dark");
	}
}
