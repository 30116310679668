// =============================================================================
// Root
// =============================================================================
.c-header {
	position: absolute;

	width: 100%;
}
// =============================================================================
// Elements
// =============================================================================
.c-header__overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-overlay;

	display: block;
	width: 100%;
	height: 100%;

	background-color: set-color("background" "dark");

	transform: scaleY(0);
	transform-origin: top center;

	transition: transform $animation-duration-2x-slow ease-in-out;
}

.c-header__container {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 2rem 0;
}

.c-header__nav-container {
	width: 100%;

	text-align: center;

	@include respond-to("viewport-9") {
		position: fixed;
		top: 8rem;
		left: 50%;

		/**
		 * the 8rem is used to place the navigation in the center of the
		 * screen on mobile
		 */
		height: calc(100% - 8rem);
		min-height: calc(100% - 8rem);
		overflow: auto;
		padding: 5rem 2rem 2rem;

		opacity: 0;

		transform: translateX(-50%);

		transition: display 0s step-start;

		visibility: hidden;

		pointer-events: none;
	}

	@include respond-at("viewport-9") {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		opacity: 1;
	}
}

.c-header__button {

	@include respond-to("viewport-9") {
		margin-top: 3rem;

		opacity: 0;

		/**
		 * 0.9 seconds is needed because the button has to appear after the
		 * navigation items are on screen.
		 * There is already a transition on the button, so it's needed to
		 * address the button component as well.
		 */
		&.c-button {
			transition: opacity 0.3s ease-in-out 0.9s;
		}
	}
}

.c-header__hamburger,
.c-header__nav-container,
.c-header__logo {
	z-index: $z-index-modal;
}

.c-header__logo {

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		flex: 1;
	}
}

// =============================================================================
// States
// =============================================================================
.c-header {

	&.is-open {

		.c-header__overlay {
			transform: scaleY(1);
		}

		.c-header__nav-container {
			opacity: 1;

			visibility: visible;

			pointer-events: auto;
		}

		.c-nav__item {
			opacity: 1;

			transform: translateY(0);
		}

		.c-header__button {
			opacity: 1;
		}
	}
}
