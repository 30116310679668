// =============================================================================
// Fonts loading
// =============================================================================
/**
 * Info
 * -----
 * Load the fonts with the get-font mixin, which loads in a font and their
 * different weights in different formats.
 */

// =============================================================================
// Proxima Nova
// =============================================================================
@include get-font("Proxima Nova", "/assets/fonts/proxima-nova/bold/proxima-nova-bold", $font-weight-bold, normal);
@include get-font("Proxima Nova", "/assets/fonts/proxima-nova/semi-bold/proxima-nova-semi-bold", $font-weight-semi-bold, normal);
@include get-font("Proxima Nova", "/assets/fonts/proxima-nova/regular/proxima-nova-regular", $font-weight-normal, normal);

// =============================================================================
// Source Sans Pro
// =============================================================================
@include get-font("Source Sans Pro", "/assets/fonts/source-sans-pro/regular/source-sans-pro-regular", $font-weight-normal, normal);
