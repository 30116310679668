// =============================================================================
// Variables
// =============================================================================

/**
 * Grid definition based on the usage of the @littlelmissrobot/sass-grid
 * library. This generates grid classes based on the configuration.
 *
 * https://www.npmjs.com/package/@littlemissrobot/sass-grid
 */

$lmr-sass-grid: (
	settings: (
		layout: 12,
		columns: (6),
		left-offsets: (),
		right-offsets: (),
		with-default-spacing: (
			viewport-0: 1rem,
			viewport-7: 2rem,
			viewport-9: 3rem
		),
		with-spacing: (),
		with-column-spacing: (),
		with-row-spacing: (),
		justify-content: (),
		align-items: (
			center: center,
		),
		align-self: (),
		order: ()
	),
	breakpoints: $lmr-sass-breakpoints,
	suffixicate: (
		viewport-7: (
			at: (
				columns: (3, 4, 6),
				order: (first: -1)
			),
		),
	),
);
