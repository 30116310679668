// =============================================================================
// Settings
// =============================================================================
$hamburger-width: 2.2rem;
$hamburger-height: 1.6rem;
$hamburger-line-size: 0.4rem;

$easing: 0.6s cubic-bezier(0.86, 0, 0.07, 1);

// =============================================================================
// Root
// =============================================================================
.c-hamburger {
	display: inline-block;
	padding: 0.8rem;

	// Reset the default styling of a <button>
	background-color: transparent;
	border: none;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		outline: inherit;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-hamburger__container {
	position: relative;

	width: $hamburger-width;
	height: $hamburger-height;

	transform-origin: center;

	transition: transform $easing;
	transition-delay: 0s;
}

.c-hamburger__text {
	@include visually-hidden();
}

.c-hamburger__line {
	position: absolute;
	right: 0;

	width: 100%;
	height: 0.2rem;

	background-color: set-color("hamburger" "default");

	transform-origin: center;

	transition: transform $easing;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-hamburger__line--first {
	top: 0;

	transition-delay: 0.15s;
	transition-duration: 0.5s;
}

.c-hamburger__line--second {
	top: 50%;

	transform: translateY(-50%);

	transition-delay: 0s;
}

.c-hamburger__line--third {
	bottom: 0;

	transition-delay: 0.15s;
	transition-duration: 0.5s;
}

// =============================================================================
// States
// =============================================================================
.c-hamburger {

	&.is-closed {

		.c-hamburger__container {
			transform: rotate(180deg);

			transition-delay: 0.15s;
		}

		.c-hamburger__line--first {
			transform:
				translateY(-0.1rem)
				translateY(calc(#{$hamburger-height} / 2))
				scaleY(1)
				rotate(-45deg);

			transition-delay: 0.25s;

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				transform:
					translateY(-0.1rem)
					translateY(0.8rem)
					scaleY(1)
					rotate(-45deg);
			}
		}

		.c-hamburger__line--second {
			transform:
				translateY(-50%)
				scaleX(0);

			transition-delay: 0s;
		}

		.c-hamburger__line--third {
			transform:
				translateY(0.1rem)
				translateY(calc(#{$hamburger-height} / -2))
				scaleY(1)
				rotate(45deg);

			transition-delay: 0.25s;

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				transform:
					translateY(0.1rem)
					translateY(-0.8rem)
					scaleY(1)
					rotate(45deg);
			}
		}
	}

	&.is-static {

		.c-hamburger__line {
			animation: none;
		}
	}
}
