// =============================================================================
// Typography
// =============================================================================

/**
 * Default and SIMPLE styles for all typographic tags. Anything else than the
 * font-style should be applied. NO MARGIN OR PADDING should be applied onto
 * these selectors.
 */

h1 {
	@include set-typo("h1");
}

h2 {
	@include set-typo("h2");
}

h3 {
	@include set-typo("h3");
}

h4 {
	@include set-typo("h4");
}

h5 {
	@include set-typo("h3");
}

h6 {
	@include set-typo("h3");
}

p {
	@include set-typo("paragraph");
}

strong {
	@include set-typo("strong");
}

// small {}

// label {}

a {
	@include set-typo("link");
}
