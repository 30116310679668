// =============================================================================
// Elements
// =============================================================================
.c-nav__list {
	@include reset-list();

	color: set-color("text" "inverse");

	@include respond-at("viewport-9") {
		display: flex;
	}

	@for $i from 1 through 8 {

		li:nth-child(#{$i}) {

			@include respond-to("viewport-9") {
				transition:
					transform 0.3s ease-in-out $i * 0.1s,
					opacity 0.3s ease-in-out $i * 0.1s;
			}
		}
	}
}

.c-nav__item {
	margin-bottom: 2rem;

	text-align: center;

	opacity: 0;

	transform: translateY(0.5rem);

	@include respond-at("viewport-9") {
		margin-right: 1.4rem;
		margin-bottom: 0;
		padding: 0;

		opacity: 1;

		transform: translateY(0);
	}

	@include respond-at("viewport-12") {
		margin-right: 3rem;
	}
}

.c-nav__link {
	@include set-typo("nav");

	position: relative;

	text-transform: uppercase;

	color: set-color("text" "inverse");

	&::after {
		content: "";

		position: absolute;
		bottom: -0.5rem;
		left: 0;

		width: 100%;
		height: 0.2rem;

		border-radius: $border-radius-small;

		background-color: set-color("background" "default");

		transform: scaleX(0);
		transform-origin: 0% 0%;

		transition: $animation-duration ease-in-out;
	}

	&:hover,
	&:active {

		&::after {
			transform: scaleX(1);
		}
	}
}
