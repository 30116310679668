/* stylelint-disable
	property-no-vendor-prefix,
	declaration-property-unit-whitelist,
	unit-whitelist */

// =============================================================================
// Normalize
// =============================================================================

/**
 * Normalization of HTML elements (ELEMENTS ONLY) to preserve useful defaults
 * and correct browser inconsistencies. Manully forked from Normalize.css. The
 * css in comments is disabled because its gets overridden by the reset.
 *
 * https://github.com/necolas/normalize.css
 */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */

html {
	/**
	 * We set the font-size on the HTML tag to 62.5% to equalize 1rem to 10px
	 * instead of 1rem to 16px.
	 */
	font-size: 62.5%;
	line-height: 1.15; // 1
}

// Remove the margin in all browsers.
body {
	margin: 0;
}

// IE11 not fully supports main, this renders `main` consistently in IE.
main {
	display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

h1 {
	// margin: 0.67em 0;

	font-size: 2em;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
	box-sizing: content-box; // 1
	// height: 0; // 1
	overflow: visible; // 2
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre,
code,
kbd,
samp {
	// stylelint-disable-next-line font-family-no-duplicate-names
	font-family: monospace, monospace; // 1
	font-size: 1em; // 2
}

// Remove the gray background on active links in IE 10.
a {
	background-color: transparent;
}

/**
 * 1. Add the correct text decoration in Chrome, Edge, Opera, and Safari.
 * 2. Remove the bottom border in Chrome 57-
 */

abbr[title] {
	text-decoration: underline; // 1
	text-decoration: underline dotted; // 1

	border-bottom: none; // 2
}

// Add the correct font weight in Chrome, Edge, and Safari.
b,
strong {
	// stylelint-disable-next-line font-weight-notation
	font-weight: bolder;
}

// Add the correct font size in all browsers.
small {
	font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in all
 * browsers.
 */

sub,
sup {
	position: relative;

	vertical-align: baseline;

	font-size: 75%;
	line-height: 0;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

// Remove the border on images inside links in IE 10.
img {
	border-style: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Change the font styles in all browsers.
 */

button,
input,
optgroup,
select,
textarea {
	margin: 0; // 1

	font-family: inherit; // 2
	font-size: 100%; // 2
	line-height: 1.15; // 2
}

// Show the overflow in IE and Edge.
button,
input {
	overflow: visible;
}

// Remove the inheritance of text transform in Edge, Firefox, and IE.
button,
select {
	text-transform: none;
}

// Correct the inability to style clickable types in iOS and Safari.
button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

// Remove the inner border and padding in Firefox.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;

	border-style: none;
}

// Restore the focus styles unset by the previous rule.
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText;
}

// Correct the padding in Firefox.
// fieldset {
// 	padding: 0.35em 0.75em 0.625em;
// }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
	box-sizing: border-box; // 1

	display: table; // 1
	max-width: 100%; // 1
	padding: 0; // 3

	white-space: normal; // 1
	line-height: inherit;

	color: inherit; // 2
}

// Add the correct vertical alignment in Chrome, Firefox, and Opera.
progress {
	vertical-align: baseline;
}

// Remove the default vertical scrollbar in IE 10+.
textarea {
	overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type="checkbox"],
[type="radio"] {
	box-sizing: border-box; /* 1 */

	padding: 0; /* 2 */
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

/**
 * 1. Correct the outline style in Safari.
 * 2. Override the extra rounded corners on iOS.
 */

[type="search"] {
	outline-offset: -2px; // 1

	-webkit-appearance: textfield; // 2
}

// Remove the inner padding in Chrome and Safari on macOS.
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

/**
 * 1. Change font properties to `inherit` in Safari.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */

::-webkit-file-upload-button {
	font: inherit; // 1

	-webkit-appearance: button; // 2
}

// Add the correct display in Edge, IE 10+, and Firefox.
details {
	display: block;
}

// Add the correct display in all browsers
summary {
	display: list-item;
}

// Add the correct display in IE 10+.
template {
	display: none;
}

/**
 * Always hide an element with the `hidden` HTML attribute. Also Add the correct
 * display in IE 10.
 */

// [hidden] {
// 	display: none;
// }
