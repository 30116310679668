// =============================================================================
// Root
// =============================================================================
.c-button {
	display: inline-flex;
	align-items: center;
	padding: 2.1rem 3.4rem;

	text-decoration: none;

	background-color: set-color("button" "default");
	border-radius: $border-radius-default;
	color: set-color("text" "inverse");

	transition: background-color $animation-duration ease-in-out;

	&:hover {
		background-color: rgba(set-color("button" "default"), 0.8);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-button__text {
	@include set-typo("button");

	white-space: nowrap;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-button--inverted {
	padding: 1rem 2rem;

	background-color: transparent;
	border: $border-light;
	color: set-color("text" "inverse");

	transition:
		background-color $animation-duration ease-in-out,
		color $animation-duration ease-in-out;

	&:hover {
		background-color: set-color("button" "inverse");
		color: set-color("text" "dark");
	}

	.c-button__text {
		@include set-typo("button-inverted");
	}
}
