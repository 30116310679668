// =============================================================================
// Elements
// =============================================================================
.c-gallery__item {
	cursor: pointer;
}

.c-gallery__visual-container {
	padding-bottom: 100%;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.c-gallery__visual {
	@include visually-hidden();
}

.c-gallery__overlay {
	display: none;
}

// =============================================================================
// States
// =============================================================================
.c-gallery__item {

	&.is-open {

		.c-gallery__overlay {
			display: flex;

			pointer-events: auto;
		}
	}
}
