// =============================================================================
// Link - (no classes)
// =============================================================================
a {
	display: inline-block;

	text-decoration: none;

	color: inherit;
}
