// =============================================================================
// Root
// =============================================================================
.c-hero {
	width: 100%;
	height: 55rem;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@include respond-at("viewport-7") {
		height: 65rem;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-hero__visual {
	@include visually-hidden();
}
