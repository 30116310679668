// =============================================================================
// Root
// =============================================================================
.c-expert {
	text-align: center;
}

// =============================================================================
// Elements
// =============================================================================
.c-expert__visual-container {
	width: 100%;
	margin-bottom: 1rem;
	padding-top: 100%;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.c-expert__visual {
	@include visually-hidden();
}

.c-expert__tag {
	@include set-typo("tag");

	margin-top: 1rem;
}
