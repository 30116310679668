// =============================================================================
// Font families
// =============================================================================
$font-family-primary: ("Proxima Nova", "Arial", sans-serif);
$font-family-secondary: ("Source Sans Pro", "Arial", sans-serif);

// =============================================================================
// Font weights
// =============================================================================
// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
$font-weight-extra-black: 950; // extra-black/ultra-black
$font-weight-black: 900; // black/heavy
$font-weight-extra-bold: 800; // extra-bold/ultra-bold
$font-weight-bold: 700;
$font-weight-semi-bold: 600; // semi-bold/demi-bold
$font-weight-medium: 500;
$font-weight-normal: 400; // normal/regular
$font-weight-light: 300;
$font-weight-extra-light: 200; // extra-light/ultra-light
$font-weight-thin: 100; // thin/hairline

// =============================================================================
// Line heights constants
// =============================================================================
$line-height-default: 1.5;
$line-height-normalize: 1;

// =============================================================================
// Font styles
// =============================================================================

/**
 * Font styles used within all the styles. Define all typography here.
 *
 * https://www.npmjs.com/package/@littlemissrobot/sass-typography
 */

$lmr-sass-typography: (
	source: (
		h1: (
			font-family: $font-family-primary,
			font-weight: $font-weight-normal,
			font-size: 3rem,
			line-height: $line-height-default,
			letter-spacing: 0.2rem,
			text-transform: uppercase,
			viewport-7: (
				font-size: 3.2rem
			)
		),
		h2: (
			font-family: $font-family-primary,
			font-weight: $font-weight-normal,
			font-size: 2.2rem,
			line-height: $line-height-default,
			letter-spacing: 0.2rem,
			text-transform: uppercase
		),
		h3: (
			font-family: $font-family-primary,
			font-weight: $font-weight-semi-bold,
			font-size: 1.6rem,
			line-height: $line-height-default,
			letter-spacing: 0.2rem,
			text-transform: uppercase
		),
		h4: (
			font-family: $font-family-primary,
			font-weight: $font-weight-semi-bold,
			font-size: 3rem,
			line-height: $line-height-default,
			letter-spacing: 0.2rem,
			text-transform: uppercase,
			viewport-7: (
				font-size: 3.2rem
			)
		),
		paragraph: (
			font-family: $font-family-secondary,
			font-weight: $font-weight-normal,
			font-size: 1.8rem,
			line-height: $line-height-default
		),
		button: (
			font-family: $font-family-primary,
			font-weight: $font-weight-bold,
			font-size: 1.4rem,
			line-height: $line-height-default,
			letter-spacing: 0.2rem,
			text-transform: uppercase,
			viewport-12: (
				font-size: 1.5rem,
			)
		),
		button-inverted: (
			font-family: $font-family-primary,
			font-weight: $font-weight-semi-bold,
			font-size: 1.3rem,
			line-height: $line-height-default,
			letter-spacing: 0.2rem,
			text-transform: uppercase
		),
		logo: (
			font-family: $font-family-primary,
			font-weight: $font-weight-semi-bold,
			font-size: 1.6rem,
			line-height: $line-height-normalize,
			letter-spacing: 0.2rem,
			text-transform: uppercase,
			viewport-7: (
				font-size: 2rem
			)
		),
		strong: (
			font-family: $font-family-secondary,
			font-weight: $font-weight-semi-bold,
			font-size: 1.8rem,
			line-height: $line-height-default
		),
		link: (
			font-family: $font-family-secondary,
			font-weight: $font-weight-normal,
			font-size: 1.8rem,
			line-height: $line-height-default
		),
		tag: (
			font-family: $font-family-secondary,
			font-weight: $font-weight-normal,
			font-size: 1.4rem,
			line-height: $line-height-default
		),
		nav: (
			font-family: $font-family-primary,
			font-weight: $font-weight-semi-bold,
			font-size: 1.3rem,
			line-height: $line-height-default,
			letter-spacing: 0.2rem
		),
		breakpoints: $lmr-sass-breakpoints
	)
);
