// =============================================================================
// Root
// =============================================================================
.c-footer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10rem 0;

	background-color: set-color("background" "dark");
}

// =============================================================================
// Elements
// =============================================================================
.c-footer__title {
	@include set-typo("nav");

	color: set-color("text" "inverse");

	font-weight: $font-weight-normal;
	text-transform: uppercase;
	text-align: center;
}
