// =============================================================================
// Root
// =============================================================================
.c-icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;

	color: currentColor;
	// Style the included SVG icon by directly selecting the SVG.
	& > svg {
		vertical-align: middle;
		width: inherit;
		height: inherit;

		fill: currentColor;

		pointer-events: none;
	}
}
